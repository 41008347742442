import React, { useEffect, useState } from 'react';
import Layout from '../components/Layout';
import Airtable from 'airtable';
import Tabs from '../components/Tabs-two/Tabs';
import {
  ArrowPathIcon,
  ArrowLeftCircleIcon,
  ArrowRightCircleIcon,
  HandThumbDownIcon,
  HeartIcon,
  QuestionMarkCircleIcon,
} from '@heroicons/react/24/outline';

const LaurjayPage = () => {
  const base = new Airtable({
    apiKey:
      'patfXWWytUL8HAMg1.132500e46b0cc4abf976f71d08c8dcbb2922df014683c014afff7be8b082a93d',
  }).base('appnTqHuXb7vBNobl');

  const [allNames, setAllNames] = useState([]);
  const [names, setNames] = useState([]);
  const [noNames, setNoNames] = useState([]);
  const [yesNames, setYesNames] = useState([]);

  const [records, setRecords] = useState([]);
  const [index, setIndex] = useState(0);

  const loadData = () => {
    base('Table 1')
      .select({ fields: ['name', 'notes', 'decision'] })
      .all()
      .then((records) => {
        setRecords(records.map((r) => ({ ...r.fields, id: r.id })));
      });
    setAllNames(records.sort((a, b) => a.name.localeCompare(b.name)));
  };

  const handleClick = (numberPassed) => {
    const nextNumber = index + numberPassed;
    if (nextNumber < 0 || nextNumber > names.length) return;
    setIndex(index + numberPassed);
  };

  const updateName = (decision, selectedRecord) => {
    const record = selectedRecord || names[index];
    base('Table 1').update([{ id: record.id, fields: { decision: decision } }]);
    handleClick(1);
  };

  const updateNameRefresh = (decision, record) => {
    updateName(decision, record);
    loadData();
  };

  const filterNames = () => {
    const yes = [],
      no = [],
      other = [];
    records.map((r) => {
      if (r.decision === 'yes') return yes.push(r);
      if (r.decision === 'no') return no.push(r);
      other.push(r);
    });

    setNames(other);
    setNoNames(no);
    setYesNames(yes);
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    filterNames();
  }, [records]);

  return (
    <Layout>
      <section className="container max-w-4xl mx-auto px-4 mt-6 min-h-full">
        <div>
          <div className="mb-2">
            <h2 className="font-headline mb-4">Baby names</h2>
          </div>
          <Tabs onClick={() => loadData()}>
            <div label="Pick">
              {!!names ? (
                names.length > 0 ? (
                  <section className="tab-pick my-4 max-w-md">
                    <div className="mt-4">
                      <h3 className="mb-4 text-center">{names[index]?.name}</h3>
                      <p>{names[index]?.notes}</p>
                    </div>

                    <div className="flex align-items-center mt-8">
                      <div
                        className="w-full cursor-pointer"
                        onClick={() => handleClick(-1)}
                      >
                        <ArrowLeftCircleIcon className="w-10 m-auto" />
                      </div>

                      <div
                        className="w-full cursor-pointer"
                        onClick={() => updateName('no')}
                      >
                        <HandThumbDownIcon className="w-10 m-auto" />
                      </div>

                      <div
                        className="w-full cursor-pointer"
                        onClick={() => updateName('maybe')}
                      >
                        <QuestionMarkCircleIcon className="w-10 m-auto" />
                      </div>

                      <div
                        className="w-full cursor-pointer"
                        onClick={() => updateName('yes')}
                      >
                        <HeartIcon className="w-10 m-auto" />
                      </div>

                      <div
                        className="w-full cursor-pointer"
                        onClick={() => handleClick(1)}
                      >
                        <ArrowRightCircleIcon className="w-10 m-auto" />
                      </div>
                    </div>
                  </section>
                ) : (
                  <p className="my-4">Ask Rachel for more names</p>
                )
              ) : (
                <p className="my-4">Loading...</p>
              )}
            </div>
            <div label="Yes">
              {yesNames.length > 0 ? (
                <table className="table-auto">
                  <tbody>
                    {yesNames
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map((record) => (
                        <tr
                          className="border-b border-gray-300"
                          key={record.id}
                        >
                          <td className="p-3">
                            <h4 className="mb-1 leading-none">{record.name}</h4>{' '}
                            <br />
                            {record.notes}
                          </td>
                          <td>
                            <div
                              className="w-full cursor-pointer p-2"
                              onClick={() => updateNameRefresh('no', record)}
                            >
                              <HandThumbDownIcon className="w-6 mx-auto my-4" />
                            </div>
                            <div
                              className="w-full cursor-pointe p-2"
                              onClick={() => updateNameRefresh('maybe', record)}
                            >
                              <ArrowPathIcon className="w-6 mx-auto my-4" />
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              ) : (
                <p className="my-4">Nothing good yet</p>
              )}
            </div>
            <div label="Nah">
              {noNames.length > 0 ? (
                <table className="table-auto">
                  <tbody>
                    {noNames
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map((record) => (
                        <tr
                          className="border-b border-gray-300"
                          key={record.id}
                        >
                          <td className="p-3">
                            <h4 className="mb-1 leading-none">{record.name}</h4>{' '}
                            <br />
                            {record.notes}
                          </td>
                          <td>
                            <div
                              className="w-full cursor-pointer p-2"
                              onClick={() => updateNameRefresh('yes', record)}
                            >
                              <HeartIcon className="w-6 mx-auto my-4" />
                            </div>
                            <div
                              className="w-full cursor-pointe p-2"
                              onClick={() => updateNameRefresh('maybe', record)}
                            >
                              <ArrowPathIcon className="w-6 mx-auto my-4" />
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              ) : (
                <p className="my-4">No rejections yet</p>
              )}
            </div>
            <div label="All">
              <table className="table-auto">
                <tbody>
                  {allNames.map((record) => (
                    <tr className="border-b border-gray-300" key={record.id}>
                      <td className="p-3">
                        <h4 className="mb-1 leading-none">{record.name}</h4>{' '}
                        <br />
                        {record.notes}
                      </td>
                      <td className="p-3 align-top">{record.decision}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </Tabs>
        </div>
      </section>
    </Layout>
  );
};

export default LaurjayPage;
